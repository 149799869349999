import React from 'react';
import './components.css';
import NavButton from './buttons/navButton';
import NavBurgerMenu from './menus/nav-burger-menu';
import ContactButton from './buttons/contact-button';
import NavHrefButton from './buttons/navHrefButton';

export default function Header() {
  return (
    <header>
      <div className='header-wrapper'>
        <div className='header-logo-wrapper zoom-on-hover-5'>
          <a className='header-company-name' href="https://xinit.no/">Xinit</a>
        </div>
        <nav>
          <ul className='nav show-on-big-screen'>
          <li><NavHrefButton type='Xamin App' page='' /></li>
          </ul>
          <div className='show-on-big-screen'><ContactButton type='Kontakt' page='contact' /></div>
        </nav>
        <div className='show-on-small-screen'>
          <div><ContactButton type='Kontakt' page='contact' /></div>
          <div className='header_burger-menu_spacing'><NavBurgerMenu/></div>
        </div>
        
      </div>
    </header>
  );
}